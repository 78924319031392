.description {
  display: block;
  max-height: 10000px;
  transition: all 150ms ease-out 0ms;

  &.collapse {
    max-height: 200px;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      background: linear-gradient(to top, #ffffffff, #ffffff00);
    }
  }
}
