.address-list {
  width: 100%;
  .item-address {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .username {
      .username-heading {
        color: #29313f;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
    }

    .address-info {
      .address-info-detail {
        display: flex;
        padding: 10px 0;

        .name-address {
          margin-left: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: #576071;
        }

        .address-detail {
          margin-left: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
      }

      .contact {
        display: flex;
        padding-bottom: 10px;

        .number-phone {
          margin-left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #576071;
        }
      }
    }
  }
}
