@import '../../../../../styles/breakpoint.less';

.image-slider {
  display: block;
}

.product-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 8px;
}

.slider-container {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .slider-item {
    display: block;
    padding: 0 8px;

    a {
      user-select: none;
    }
  }

  .button-prev {
    height: 32px;
    width: 32px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 8px 32px rgba(20, 146, 187, 0.16);
    z-index: 1;
    transition: transform 150ms ease-in-out 0ms;

    @media @lg {
      height: 40px;
      width: 40px;
    }

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }

  .button-next {
    height: 32px;
    width: 32px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 8px 32px rgba(20, 146, 187, 0.16);
    z-index: 1;
    transition: transform 150ms ease-in-out 0ms;

    @media @lg {
      height: 40px;
      width: 40px;
    }

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }
}
