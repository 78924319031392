@import '../../../styles/layout.less';

.product-detail-container {
  .container;
  padding: 16px 16px;
}

.filter-chip {
  font-size: 14px;
  color: #798395;
  background-color: #ffffff;
  border-radius: 9999px;
  padding: 3px 8px;
  line-height: 1;
  cursor: pointer;

  &.active {
    color: #ffffff;
    background: linear-gradient(225deg, #56b8d2 0%, #0f8fb9 100%);
  }
}

.filter-price-chip {
  display: inline-block;
  font-size: 14px;
  color: #798395;
  border-radius: 9999px;
  padding: 4px 8px;
  line-height: 1;
  cursor: pointer;
  background: #f0f3f8;

  &.active {
    color: #1a9cc6;
    background: #e0f3f7;
    border: 1px solid #1a9cc6;
  }
}
