@import '../../../../../styles/layout.less';

.shop-online-category-slider {
  .container;

  .slick-dots {
    width: auto;
    top: -40px;
    right: 0;

    li {
      width: auto;

      button {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 9999px;
        background-color: #b1e1ea;
        opacity: 1;
        transition: all 200ms ease-in-out 0ms;

        &:hover {
          background-color: #1a9cc6;
        }

        &::before {
          display: none;
        }
      }
    }

    .slick-active button {
      background-color: #1a9cc6;
      padding: 0 12px;
    }
  }
}
