.loginWrapper::before {
  background: linear-gradient(180deg, #b8f3ff 0%, rgba(224, 243, 247, 0) 100%);
  opacity: 0.3;
  content: '';
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}
