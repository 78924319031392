.header-menu-label {
  .label {
    color: #576071;
    font-weight: 600;
  }

  img {
    width: 21px;
    margin-bottom: 2px;
  }
}

.header-vertical-btn-action {
  position: fixed;
  // bottom: 0px;
  left: 0px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  margin-top: 100px;

  .sign-up {
    background: #e0f3f7;
    border-radius: 8px;
    color: #1a9cc6;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 90px;
    width: 90vw;
    height: 48px;
  }

  .login {
    background: #1a9cc6;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 30px;
    line-height: 22px;
    height: 48px;
    width: 90vw;
    margin-top: 10px;
  }
}

.ant-menu-root:first-child {
  .ant-menu-title-content {
    display: flex;
    align-items: center;
  }
}

.ant-menu-submenu.not-background.hide-at-desktop {
  display: none;
}

.ant-menu-submenu {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
@media (min-width: 900px) and (max-width: 1200px) {
  span.ant-menu-title-content {
    font-size: 13px;
  }
}
@media (min-width: 1200px) {
  span.ant-menu-title-content {
    font-size: 13px;
  }
}
@media (max-width: 1200px) {
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    margin: 0 !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: none !important;
  }
  .ant-menu-submenu {
    border-bottom: 1px solid #b1e1ea;
    // margin: 0 10px;
  }
  .have-line {
    border-bottom: 1px solid #b1e1ea;
  }
  .ant-menu-submenu.not-background.hide-at-desktop {
    display: block;
  }
  .ant-menu-submenu.not-background {
    border-bottom: unset;
  }
  .ant-menu-sub.ant-menu-inline {
    background: none;
  }
  li.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    li:nth-child(8),
    li:nth-child(9) {
      display: none;
    }
  }

  .ant-menu-item.ant-menu-item-only-child:first-child {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .ant-menu-item.ant-menu-item-only-child.menu-item-custom {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ant-menu-item-custom {
  margin: 0 10px !important;
}
.ant-menu-submenu .ant-menu-item-active {
  background-color: #e0f3f7 !important;
}
.not-background.ant-menu-submenu .ant-menu-item-active {
  background-color: white !important;
}
.not-background.ant-menu-submenu .ant-menu-item.menu-item-custom {
  padding-left: 24px !important;
}
.ant-menu-item-only-child:last-child.ant-menu-item {
  margin-bottom: 15px !important;
}
