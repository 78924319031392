*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  margin: 0;
  padding: 0;
  border-style: solid;
  border-color: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin-bottom: 0;
}

ol,
ul {
  list-style: none;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

button {
  outline: none;
}

a {
  text-decoration: unset;
  color: unset;
}

a:hover {
  color: currentColor;
}
