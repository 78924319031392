@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.top-new-item {
  position: relative;
  transition: 0.3s;
  cursor: pointer;
  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(41, 49, 63, 0) 0%, #29313f 100%);
    .item-title {
      position: absolute;
      left: 32px;
      bottom: 24px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .sub-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        padding: 3px 6px;
        color: @color-text-white;
        background: @color-primary;
        border-radius: 4px;
      }
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: @color-text-white;
      }
    }
  }
  p {
    width: fit-content;
    margin: 0;
  }
}

.item-0 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  .item-content {
    .item-title {
      .title {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
.item-1 {
  grid-column: 3;
  grid-row: 1;
}
.item-2 {
  grid-column: 3;
  grid-row: 2;
}

.top-new-item:hover {
  box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
}

@media (max-width: 992px) {
  .top-new-item {
    .item-content {
      .item-title {
        left: 24px;
        bottom: 12px;
        .sub-title {
          font-size: 11px;
          line-height: 16px;
        }
        .title {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  .item-0 {
    .item-content {
      .item-title {
        .title {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .top-new-item {
    .item-content {
      .item-title {
        left: 6px;
        bottom: 12px;
        .sub-title {
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          padding: 3px 6px;
        }
        .title {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
