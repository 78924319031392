@import '../../../../styles/user/colors.less';
@import '../../../../styles/user/styles.less';

.footer-container {
  position: relative;
  display: flex;
  padding: 24px 12px;

  @media (max-width: @break-point-6) {
    padding: 10px 10px;
  }

  .icon-rectangle {
    margin-right: 12px;
    svg {
      height: 8px;
      width: 8px;
    }
  }

  .footer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(180deg, #b8f3ff 0%, #f7f7f7 100%);
    opacity: 0.3;
  }

  .footer {
    .layout-width;
    min-height: 224px !important;
    // height: 224px !important;

    @media (max-width: @break-point-6) {
      .footer-row-point {
        justify-content: center;
        .footer-logo-sophie {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .icon-rectangle {
        display: none;
      }
    }

    @media (max-width: @break-point-1) {
      .footer-row-point {
        justify-content: center;
        .footer-logo-sophie {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .icon-rectangle {
        display: none;
      }
    }

    @media (max-width: @break-point-5) {
      .footer-row-point {
        justify-content: center;

        .footer-logo-sophie {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .icon-rectangle {
        display: none;
      }
    }

    @media (max-width: @break-point-4) {
      .footer-row-point {
        justify-content: center;

        .footer-logo-sophie {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .icon-rectangle {
        display: none;
      }
    }
    .footer-item {
      display: flex;
      flex-direction: column;
      justify-content: start;

      padding-top: 20px;
      .list-image {
        flex-wrap: nowrap;

        @media (max-width: @break-point-6) {
          margin-top: 16px;
        }
      }

      .rev {
        svg {
          transition: 0.5s;
        }
      }

      .wrap-list-info > a {
        display: block;
        margin-top: 6px;
      }

      @media (max-width: @break-point-1) {
        transition: 0.5s;
        .wrap-list-info {
          height: 0;
          max-height: 0;
          transition: 0.5s;
          overflow: hidden;
        }
        .show-option {
          height: 100%;
          max-height: 100%;
        }
        .rev {
          svg {
            transform: rotate(180deg);
          }
        }
        .ant-row-end {
          justify-content: flex-start;
        }
      }
    }

    .footer-logo-sophie {
      width: 180px;
      height: 56px;
      .gap-row;

      @media (max-width: @break-point-1) {
        display: flex;
        justify-content: flex-start;
      }
    }

    .footer-primary-text {
      // .gap-row;
      color: @color-primary;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;

      @media (max-width: @break-point-1) {
        text-align: flex-start;
      }
    }
    .mobile-down-icon {
      display: none;
      margin-left: 9px;

      @media (max-width: @break-point-1) {
        display: block;
      }
    }

    .footer-text {
      .gap-row;
      margin: 0px;
      color: @color-text-n80;
      font-size: 14px;

      @media (max-width: @break-point-1) {
        text-align: flex-start;
      }
    }

    .copyright-text {
      .gap-row;
      margin: 0px;
      margin-top: 24px;
      color: @color-text-n80;
      font-size: 14px;

      @media (max-width: @break-point-1) {
        text-align: flex-start;
      }
    }
    // .for-desktop {
    //   @media (max-width: @break-point-1) {
    //     display: none;
    //   }
    // }
    // .for-mobile {
    //   @media (max-width: @break-point-3) {
    //     display: none;
    //   }
    // }

    @media (max-width: @break-point-3) {
      .for-mobile {
        display: none;
      }
    }

    @media (max-width: @break-point-1) {
      .for-desktop {
        display: none;
      }

      .for-mobile {
        display: block;
        text-align: center;
      }
    }

    .footer-tax-number {
      color: @color-text-n100;
      font-weight: 700;
      line-height: 22px;
    }

    .footer-hotline {
      .footer-primary-text;
      .gap-row;
      text-align: right;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      // white-space: pre;
    }

    .footer-logo-rectangle {
      width: 8px;
      height: 8px;
      margin-right: 8px;
    }

    .footer-logo-social {
      width: 24px;
      height: 24px;
      margin-left: 7px;
    }

    .footer-image-bocongthuong {
      width: 127px;
      height: 48px;
    }

    .footer-image-dmca {
      width: 90px;
      height: 32px;
      margin-left: 7px;
    }

    .gap-row {
      margin-bottom: 8px;
    }
  }
}
