@import '../../../../../styles/user/styles.less';

.hospital-bar {
  .layout-width;
  background: #ecfafd;
  border-radius: 32px;
  margin-bottom: 80px;
  padding: 10px;
  @media (min-width: @break-point-1) {
    width: 80vw;
  }
  .item {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 112px;
      height: 84px;
    }
  }

  @media (max-width: @break-point-1) {
    width: 90vw;
  }
}
