@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.introduce-container {
  position: relative;
  width: 100vw;

  .introduce-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .introduce {
    .layout-width;
    padding: 50px 15px;

    .left {
      @media (max-width: @break-point-1) {
        text-align: center;
      }

      .title {
        .layout-width;

        @media (min-width: @break-point-1) {
          margin: unset;
          padding-top: 10px;
        }

        font-weight: 800;
        font-size: 28px;
        line-height: 48px;
        color: #212733;
        margin-bottom: 16px;
        width: 80%;

        span:nth-of-type(1) {
          color: #7a89a4;
        }

        span:nth-of-type(2) {
          color: @color-primary;
        }
      }

      .subtitle {
        .layout-width;
        @media (min-width: @break-point-1) {
          margin: unset;
          padding: 20px 0;
        }
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #798395;
        margin-bottom: 24px;
        width: 70%;
      }

      .sub-subtitle {
        .layout-width;

        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: @color-text-n100;
        margin-bottom: 16px;
      }

      .img-sophie-application {
        .layout-width;
        @media (min-width: @break-point-1) {
          margin: unset;
        }
        width: 195px;
        @media (max-width: @break-point-1) {
          width: 195px;
        }
        margin-bottom: 24px;
      }

      .social {
        margin-bottom: 55px;

        @media (max-width: @break-point-1) {
          margin-bottom: 50px;
        }

        .img-social {
          width: 136px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }

      .search-bar {
      }
    }

    .right {
      .img-mobile-booking {
        width: 556px;
        width: 100%;
      }
    }
  }
}
