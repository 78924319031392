@import '../../../../../styles/user/styles.less';
@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/layout.less';

.confident-container {
  .container;
  padding: 80px 16px;

  .img-building {
    display: none;
  }

  @media (max-width: @break-point-1) {
    .img-building {
      position: relative;
      .img-building-mask {
        position: absolute;
        top: 0;
        right: 0;
        width: 28%;
        transform: translate(32%, -41%);
      }
    }
  }

  .left {
    z-index: 1;
    @media (max-width: @break-point-1) {
      text-align: center;
    }

    .title {
      margin-top: 20px;
      font-weight: 800;
      font-size: 21px;
      line-height: 32px;
      color: #29313f;
      margin-bottom: 16px;

      span {
        color: #e5714b;
      }
    }

    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #798395;

      margin-bottom: 24px;
    }

    .sub-subtitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: @color-text-n100;

      margin-bottom: 16px;
    }

    .social {
      margin-bottom: 55px;

      .img-social {
        width: 136px;
        margin-right: 16px;
      }
    }
  }

  .right {
    z-index: 0;
    position: relative;

    .img-mobile-booking {
      width: 653px;
    }

    img {
      width: 552px;
      height: 456px;
      width: 100%;
      height: 100%;
    }

    .right-mask-overlay {
      position: absolute;
      top: -25px;
      right: -35px;
      pointer-events: none;

      img {
        width: 131px;
        height: 135px;
      }
    }

    @media (max-width: @break-point-1) {
      // display: none !important;
    }
  }

  .number-of-user {
    margin-top: -80px;
    @media (max-width: 1200px) {
      margin-top: -20px;
    }
    background-color: @color-text-white;
    box-shadow: 0px 16px 48px #ffd4bd;
    border-radius: 16px;
    padding: 20px 40px;
    z-index: 100;
    width: 76%;

    .number-container {
      .number {
        font-weight: 800;
        font-size: 36px;
        line-height: 48px;
        text-align: center;
        background: linear-gradient(180deg, #e5714b 0%, #ffd1c1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .description {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: @color-text-n100;
        width: 75%;
        margin: 0 auto;
      }
    }

    @media (max-width: @break-point-1) {
      width: 100%;
      margin-top: 0px;
    }
  }
}
