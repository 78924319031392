@import '../../../styles/layout.less';

.search-container {
  .container;
  padding: 16px 16px;
}

.filter-chip {
  font-size: 14px;
  color: #798395;
  background-color: #ffffff;
  border-radius: 9999px;
  padding: 3px 8px;
  line-height: 1;
  cursor: pointer;

  &.active {
    color: #ffffff;
    background: linear-gradient(225deg, #56b8d2 0%, #0f8fb9 100%);
  }
}
