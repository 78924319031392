@import '../../../../styles/user/colors.less';
@import '../../../../styles/user/styles.less';
@import '../../../../styles/layout.less';

.icon {
  display: flex;
  align-items: center;
  img {
    display: block;
  }
}

.sider-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh !important;

  .sider {
    height: 100vh !important;
    position: fixed;
  }
}

.header-toggle {
  display: none;
  color: @color-primary;
  z-index: 100;
  margin-right: -120px;
  img {
    width: 32px;
    height: 32px;
  }

  @media (max-width: @break-point-1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.header-logo-sophie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-image {
    display: flex;
    align-items: center;
  }
  img {
    width: 130px;
    height: 40px;
  }

  .toggle-btn {
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 2px;
  }
}

.header-horizon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.header-img-logo-sophie {
  width: 130px;
  height: 40px;
}

.header-menu-item-horizontal {
  font-weight: 500;

  @media (max-width: @break-point-1) {
    display: none !important;
  }
}

.main-container {
  margin-top: 72px;
}

.menu-submenu-title {
  margin-bottom: 10px;
}
.main-download {
  margin-top: 15px;
}
.item-download {
  display: inline-block;
  &::before {
    display: none;
  }
}
.img-social {
  width: 150px;
  margin-right: 16px;
}

.header-menu-horizontal {
  // width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom: none !important;
  position: relative;
  li {
    margin: 0 !important;
  }
  .ant-menu-item-selected {
    background: white !important;
  }
}

.header-menu-vertical {
  li {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
