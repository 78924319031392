.item-download {
  display: inline-block;
  &::before {
    display: none;
  }
}
.img-social {
  width: 150px;
  margin-right: 16px;
}
