@import './breakpoint.less';

.container {
  width: 100%;
  max-width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  @media @xs {
    // max-width: @screen-xs-min;
  }

  @media @sm {
    max-width: @screen-sm-min;
  }

  @media @md {
    max-width: @screen-md-min;
  }

  @media @lg {
    max-width: @screen-lg-min;
  }

  @media @xl {
    // max-width: @screen-xl-min;
    max-width: 86vw;
  }

  @media @2xl {
    max-width: 86vw;
  }
}
