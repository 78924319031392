@import '../layout.less';
@app-width: 87vw;
@break-point-1: 992px;
@break-point-2: 1294px;
@break-point-3: 1600px;
@break-point-4: 768px;
@break-point-5: 576px;
@break-point-6: 375px;

.layout-width {
  .container;
}
