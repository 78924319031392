.add-new-address {
  width: 100%;
  padding-bottom: 20px;

  .address-type-item {
    display: flex;
    background-color: #fff;
    padding: 14px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    border: 2px solid #fff;

    &.active {
      background-color: #e0f3f7 !important;
      border: 2px solid #1a9cc6;
      color: #1a9cc6;
    }
  }
}
