@import '../../../../styles/layout.less';

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(69, 125, 245, 0.08);
  z-index: 1000;
}

.header {
  .container;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
  }

  &__search {
    display: none;
    padding: 0 32px;
    flex-grow: 1;
    @media @md {
      display: block;
    }

    &--mobile {
      .container;
      padding: 0 16px 16px;
      @media @md {
        display: none;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 16px;
    }

    .menu-desktop {
      display: none;

      @media @md {
        display: block;
      }
    }

    .bag {
      position: relative;

      .bag-icon {
        display: block;
        width: 28px;
      }

      .badge {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #f0224f;
        border: 2px solid white;
        font-size: 10px;
        line-height: 1;
        padding: 2px 3px;
        border-radius: 9999px;
        color: white;
        font-weight: 600;
        pointer-events: none;
      }
    }

    .menu-mobile {
      @media @md {
        display: none;
      }
    }

    .account {
      align-items: center;
      display: none;
      cursor: pointer;
      position: relative;
      height: 70px;

      @media @md {
        display: flex;
      }

      > *:not(:last-child) {
        margin-right: 6px;
      }

      > div > span {
        color: #576071;
        font-size: 11px;
        white-space: nowrap;
      }

      .name {
        color: #1a9cc6;
        font-weight: 700;
        white-space: nowrap;
      }

      .account-popover {
        position: absolute;
        top: 100%;
        right: 0;
        width: 240px;
        padding: 8px;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
        z-index: 10;
        transition: all 150ms ease-in-out 0ms;
        opacity: 0;
        transform: scale(0.9);
        pointer-events: none;

        ul {
          a,
          div {
            display: flex;
            align-items: center;
            border-radius: 12px;
            font-weight: 600;
            color: #576071;
            padding: 8px 12px;
            line-height: 1;
            transition: all 150ms ease-in-out 0ms;

            &:hover {
              background-color: #e0f3f7;
              color: #1a9cc6;
            }
          }

          img {
            width: 20px;
            margin-right: 12px;
          }
        }
      }

      &:hover .account-popover {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
      }
    }
  }
}
