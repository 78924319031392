@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.top-new-item {
  cursor: pointer;
  height: 100%;
  padding: 16px;
  border-radius: 16px;

  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    @media (max-width: 768px) {
    }
  }
  p {
    color: #798395 !important;
    font-size: 14px;
  }
  .item-title {
    padding-top: 20px;

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: @color-text-n45 !important;
    }
    .date {
      padding: 8px 0;
      p {
        color: @color-text-n80;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        white-space: pre-wrap;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        strong {
          color: @color-primary;
          font-size: 14px;
        }
      }
    }
    .content-ellipsis {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  p {
    width: fit-content;
    margin: 0;
  }
}

.top-new-item:hover {
  box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
}
