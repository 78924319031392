@import '../../../../styles/breakpoint.less';
@import '../../../../styles/layout.less';

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(69, 125, 245, 0.08);
  z-index: 1000;
}

.header {
  .container;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;


  &__right {
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 16px;
    }

    .menu-desktop {
      display: none;

      @media @lg {
        display: block;
      }
    }
    
    .menu-mobile {
      @media @lg {
        display: none;
      }
    }

    .bag {
      position: relative;

      .bag-icon {
        display: block;
        width: 28px;
      }

      .badge {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #f0224f;
        border: 2px solid white;
        font-size: 10px;
        line-height: 1;
        padding: 2px 3px;
        border-radius: 9999px;
        color: white;
        font-weight: 600;
        pointer-events: none;
      }
    }
  }
}
