.image {
  position: relative;

  .image-control {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .ic-close {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    margin-top: 22px;
    margin-right: 25px;
    cursor: pointer;
    z-index: 1;
  }

  .img-content {
    width: 100%;
    object-fit: cover;
    height: 100%;
    max-height: 282px;
  }
}

.promotion-detail {
  .title {
    color: #1a9cc6;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    padding: 16px 0;
  }
  .promotion-share {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date-time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        color: #798395;
        font-size: 14px;
        line-height: 22px;
        margin-left: 5px;
      }
    }
    .share {
      img {
        width: 15px;
        height: 16px;
      }
      p {
        margin-left: 3px;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: #1a9cc6;
      }
    }
  }
  .content-description {
    max-height: 160px;
    overflow-y: auto;
    padding-right: 10px;

    .promotion-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #798395;
      padding: 20px 0;
    }
    .content-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #576071;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #798395;
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
