@import '../../../styles/user/colors.less';
@import '../../../styles/user/styles.less';
@import '../../../styles/layout.less';

.promotions {
  .container;
  padding: 16px 16px;
  position: relative;
  padding-bottom: 90px;

  .list-item {
    @media (max-width: @break-point-5) {
      padding-left: 16px;
    }

    .top-new-item {
      cursor: pointer;

      .banner {
        width: 100%;
        border-radius: 16px;
        height: 184px;
        object-fit: cover;
      }

      .title {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
        color: #29313f;
        font-weight: 700;
      }

      .date {
        margin-top: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          color: #798395;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          margin-left: 5px;
        }
      }
    }
  }

  .loading-scroll {
    width: 100%;
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    row-gap: 20px;

    @media (max-width: @break-point-1) {
      grid-template-columns: auto auto;
    }

    @media (max-width: @break-point-5) {
      grid-template-columns: 100%;
    }

    .control-loading {
      position: absolute;
      bottom: 0;
      transform: translateY(-55%);
      left: 0;
      right: 0;

      .loading {
        margin: 0 auto;
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;

        @-moz-keyframes spin {
          100% {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
