@import '../../../styles/user/colors.less';
@import '../../../styles/user/styles.less';
@import '../../../styles/layout.less';

.layout-width {
  padding-top: 64px;
  padding-bottom: 88px;
  .banner {
    .banner-left {
      padding-right: 12px;
      .title-sub {
        font-weight: 700;
        color: #576071;
      }
      .banner-title {
        display: flex;
        align-items: center;
        column-gap: 32px;
        padding-bottom: 28px;
        .icon-call {
          width: 72px;
          height: 72px;
        }
        .title {
          display: flex;
          flex-direction: column;
          font-weight: 800;
          font-size: 28px;
          line-height: 40px;
          span {
            color: @color-primary;
          }
        }
        .titleAtHome {
          span {
            color: @color-text-n100;
          }
          color: #a172d2;
        }
        .titleAtHost {
          span {
            color: @color-text-n100;
          }

          color: #f44067;
        }
        .titleAtFast {
          span {
            color: @color-text-n100;
          }
          color: #f5b11e;
        }
      }
      .sub-subtitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: @color-text-n100;

        margin-bottom: 16px;
      }
      .list-treatment {
        list-style: none;
        .list-item {
          display: flex;
          align-items: center;
          padding-bottom: 12px;
          .text {
            color: #576071;
            font-weight: 700;
            margin-left: 12px;
          }
        }
      }
      .social {
        margin-bottom: 55px;
        flex-wrap: nowrap;

        .img-social {
          width: 136px;
          margin-right: 16px;
        }
      }
    }
    .banner-right {
      text-align: center;
      padding-left: 12px;
      width: 100%;
      .image-banner {
        width: 100%;
        max-width: 528px;
      }
    }
  }
}

.apppointment-step-wrap {
  padding-top: 40px;
  width: 100%;

  .wrap-title {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 48px 0;
    row-gap: 12px;
    text-align: center;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 40px;
      span {
        color: #29313f;
        font-weight: 800;
        font-size: 28px;
        line-height: 40px;
      }
      span:first-child {
        color: @color-primary;
      }
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.005em;
      color: #798395;
    }
  }
  .list-appoitment-step {
    width: 100%;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
  }
}

@media (max-width: 992px) {
  .layout-width {
    width: 100%;
    padding: 50px 24px;
    .banner {
      .banner-left {
        padding: 0;
        .banner-title {
          flex-wrap: nowrap;
          column-gap: 16px;
          .title {
            font-size: 24px;
            line-height: 32px;
            span {
              font-size: 24px;
            }
          }
          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }
        .social {
          margin-bottom: 0;
        }
      }
      .banner-right {
        padding: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .apppointment-step-wrap {
    .wrap-title {
      .title {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        font-size: 24px;
        line-height: 32px;
        span {
          font-weight: 800;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
