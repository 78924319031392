.product-thumb-container {
  width: 80%;
  margin: 0 auto;

  .slick-current {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 8px;

    .thumb {
      border-radius: 8px;
      border: 2px solid #56b8d2;
    }
  }

  .thumb {
    width: 90% !important;
    margin: auto;
  }
}
