.overlay-loading {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
