@import '../../../../../styles/user/styles.less';
@import '../../../../../styles/user/colors.less';

.service-offline-container {
  .layout-width;
  margin-bottom: 70px;

  .service-offline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 40px;
      color: @color-text-n100;
      margin-bottom: 5px;
      text-align: center;
      span {
        color: @color-primary;
      }
    }

    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.005em;
      text-align: center;
      color: @color-text-n80;
    }

    .item-container {
      .layout-width;
      align-items: stretch;
      display: flex;
      justify-content: center;

      .item {
        .layout-width;
        height: 100%;
        padding: 15px;
        margin: auto;
        cursor: pointer;
        border-radius: 12px;

        &:hover {
          .button {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        @media (max-width: @break-point-1) {
          height: auto !important;
          &:hover {
            .button {
              display: none !important;
            }
          }
        }

        @media (max-width: @break-point-2) {
          height: auto !important;
        }

        &:hover {
          box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);

          .img {
            img {
              padding-top: 5px !important;
              padding-right: 5px !important;
            }

            .overlay {
              top: 3px !important;
              right: 3px !important;
            }
          }
        }

        .img {
          margin-bottom: 28px;
          position: relative;

          img {
            width: 257px;
            height: 193px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            border-radius: 12px;
          }

          .overlay {
            position: absolute;
            top: 15px;
            right: 10px;
            width: 100%;
            height: 100%;
            z-index: -1;
            border-radius: 12px;
            transition: all 0.5s;
            opacity: 0.1;
          }
        }

        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          text-align: left;
          color: @color-primary;
          width: 100%;

          @media (max-width: @break-point-1) {
            text-align: center;
          }
        }

        .subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.005em;
          color: #798395;
          word-wrap: break-word;
          text-align: left;
          width: 100%;

          @media (max-width: @break-point-1) {
            text-align: center;
          }
        }

        .button {
          border: none;
          outline: none;
          height: 40px;
          width: 40px;
          border-radius: 12px;
          background: #f4f6f8;
          display: none;
          transition: all 0.5s;

          img {
            width: 19px;
            height: 18px;
          }
        }
      }
    }
  }
}
