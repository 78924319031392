@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.hiring-detail-container {
  background-color: @color-text-white;
  width: 100%;

  .hiring-detail {
    .layout-width;
    padding: 50px 16px;

    .title {
      margin-bottom: 10px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #1a9cc6;
      }
    }
  }
}
