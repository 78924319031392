@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.searchbar {
  width: 100%;
  position: relative;
  border-radius: 16px;
  box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
  @media (max-width: 1200px) and (min-width: 991px) {
    width: calc(100% + 40px);
  }
  .input {
    width: 100%;
    height: 68px;
    border-radius: 16px;
    background: @color-text-white;
    outline: none;
    border: none;
    padding-left: 55px;
    padding-right: 130px;
    font-size: 14px;
    text-overflow: ellipsis;
  }

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #b6bdcb;
  }

  .icon {
    color: @color-primary;
    position: absolute;
    top: 21px;
    left: 20px;
    font-size: 30px;
  }

  .button {
    background: @color-primary;
    position: absolute;
    top: 18px;
    right: 10px;
    border-radius: 8px;
    width: 94px;
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
}
