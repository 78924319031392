@import '../../../../../styles/layout.less';

.shop-online__home-page--promotion {
  .slick-list {
    width: 100%;
    overflow: unset;
    @media @sm {
      overflow: hidden;
    }
  }
}
