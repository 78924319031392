@import '../../../../../styles/user/styles.less';
@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/layout.less';

.product-category-container {
  .container;
  padding: 50px 16px;

  .title {
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    color: #29313f;
    margin-bottom: 5px;
    text-align: left;
  }

  .see-all {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #1a9cc6;
    mix-blend-mode: normal;
    cursor: pointer;
  }

  .header {
    margin-bottom: 24px;
    text-align: center;

    @media (max-width: @break-point-1) {
      text-align: center;
    }

    .see-all {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      color: #1a9cc6;
      mix-blend-mode: normal;
      cursor: pointer;
    }
  }

  .category-list-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
    padding-top: 12px;

    @media (max-width: 1100px) {
      padding-bottom: 40px;
    }

    .item {
      height: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #576071;
      flex-basis: calc(100% / 8);

      @media (max-width: 1100px) {
        flex-basis: calc(100% / 6);
      }

      @media (max-width: @break-point-1) {
        flex-basis: calc(100% / 3);
      }

      .wrap-inside {
        background: #ffffff;
        padding: 12px 5px;
        height: 100%;
        border-radius: 16px;
      }

      img {
        width: 56px;
        height: 56px;
        margin-bottom: 10px;
      }
    }
  }
}

.product-inday-container {
  .container;
  padding: 50px 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .slide-container {
    margin: 0 -6px;
    .slide-items {
      padding: 0 6px;
    }

    > div > div {
      display: flex !important;
      height: 100%;
      gap: 8px;
    }
  }

  .product-inday {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 24px 0;

    @media @md {
      border: 4px solid #1a9cc6;
      border-radius: 14px;
    }

    .overlay-text {
      position: absolute;
      top: -25px;
      left: 30px;
      font-weight: 800;
      font-size: 24px;
      line-height: 40px;
      background-color: #e9f7f9;
      color: @color-primary;
      width: max-content;
      padding-right: 20px;
      padding-left: 20px;

      @media (max-width: @break-point-1) {
        font-size: 22px;
        left: unset;
        padding-left: unset;
        padding-right: unset;
      }
    }

    .overlay-left-button,
    .overlay-right-button {
      position: absolute;
      top: 50%;
      background-color: white;
      color: black;
      width: max-content;
      border-radius: 8px;
      outline: none;
      border: none;
      width: 48px;
      height: 48px;
      box-shadow: 0px 8px 32px rgba(20, 146, 187, 0.16);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;

      img {
        width: 24px;
        height: 23px;
      }
    }

    .overlay-left-button {
      left: -12px;
    }

    .overlay-right-button {
      right: -12px;
    }

    .product-container {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      height: 90%;
      width: 94% !important;

      @media @md {
        width: 98% !important;
      }

      :global(.swiper-slide) {
        margin-right: 12px !important;
      }

      > div {
        width: 100%;
        overflow: hidden;

        > div {
          display: flex;

          > div {
            display: block;
          }
        }
      }

      .item-product {
        background-color: @color-text-white;
        border-radius: 16px;
        padding: 15px;
        width: 100%;
        height: 296px;
        cursor: pointer;
        position: relative;
        text-overflow: ellipsis;

        &:hover {
          .img {
            scale: 1.1;
            z-index: -1;
          }
        }

        .overlay-sale {
          position: absolute;
          top: 0;
          right: 0px;
          z-index: 1;

          img {
            width: 51px;
            height: 32px;
            z-index: 0;
          }
        }

        .overlay-sale-text {
          position: absolute;
          top: 5px;
          right: 10px;

          .text {
            z-index: 10000;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #ffffff;
          }
        }

        .product-image {
          overflow: hidden;
        }

        .img {
          width: 164px;
          width: 100%;
          aspect-ratio: 1;
          z-index: -1;
          transition: all 1s;
          min-height: 164px;
        }

        .label {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.005em;
          color: #576071;
          mix-blend-mode: normal;
          min-height: 66px !important;
          height: 66px !important;
        }

        .price {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #f0224f;
          mix-blend-mode: normal;
        }

        .old-price {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.005em;
          text-decoration-line: line-through;
          color: #798395;
          mix-blend-mode: normal;
        }

        .button {
          background: #1a9cc6;
          border-radius: 8px;
          outline: none;
          border: none;
          color: white;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

.promotion-container {
  .container;
  padding: 50px 16px;
  margin-bottom: 80px;

  .title {
    font-weight: 800;
    font-size: 20px;
    line-height: 40px;
    color: #29313f;
    text-align: center;

    @media @sm {
      font-size: 28px;
    }
  }

  .see-all {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #1a9cc6;
    mix-blend-mode: normal;
    cursor: pointer;
  }

  .item-container {
    padding-top: 16px;

    .item {
      cursor: pointer;
      border-radius: 16px;

      img {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
      }

      @media (max-width: @break-point-1) {
        .layout-width;
      }
    }
  }
}
