.root-blog {
  .wrap-pagination {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .ant-pagination-item {
    border: none;
    a {
      font-size: 14px;
      color: #576071;
      font-weight: 600;
    }
  }
  .ant-pagination-item-link {
    border: none;
  }
  .ant-pagination-item-active {
    background: #1a9cc6;
    a {
      font-weight: 600;
      color: white;
    }
  }
}
