@import '../../../../../styles/layout.less';

.slider-container {
  margin: -4px;

  @media @sm {
    margin: -8px;
  }

  .slider-item {
    display: block;
    padding: 4px;

    @media @sm {
      padding: 8px;
    }

    a {
      user-select: none;
    }
  }
}

.paper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 6px;
  border-radius: 8px;
  text-align: center;
  line-height: 1.25;
  font-weight: 600px;
  color: #576071;
  min-height: 120px;
  @media @sm {
    padding: 12px;
    aspect-ratio: 1;
  }

  img {
    user-select: none;
  }

  h6 {
    font-size: 10px;
    line-height: 1.25;
    @media @sm {
      font-size: 12px;
    }
  }
}
