@import '../../../../../styles/layout.less';

.product-promotion {
  position: relative;
  margin-top: 40px;
  padding: 32px 0px 0px;

  @media @md {
    padding: 32px 12px 12px;
    border: 4px solid #56b8d2;
    border-radius: 16px;
  }

  h4 {
    position: absolute;
    top: 0;
    transform: translate(0, -60%);
    margin: 0;
    background-color: #e1faff;
    @media @md {
      color: #0f8fb9;
      left: 20px;
      padding: 0 4px;
    }
  }
}

.slider-container {
  margin: 0 -8px;
  position: relative;

  .slider-item {
    display: block;
    padding: 0 8px;

    a {
      user-select: none;
    }
  }

  .button-prev {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 8px 32px rgba(20, 146, 187, 0.16);
    z-index: 1;
    transition: transform 150ms ease-in-out 0ms;

    &:hover {
      transform: translate(-50%, -50%) scale(1.05);
    }

    &:active {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .button-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 8px 32px rgba(20, 146, 187, 0.16);
    z-index: 1;
    transition: transform 150ms ease-in-out 0ms;

    &:hover {
      transform: translate(50%, -50%) scale(1.05);
    }

    &:active {
      transform: translate(50%, -50%) scale(1);
    }
  }
}
