@import '../../../../../styles/layout.less';
@import '../../../../../styles/user/colors.less';

.top-partner-container {
  .container;
  padding: 80px 16px;

  .header {
    margin-bottom: 40px;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      color: #29313f;
      margin-bottom: 16px;

      span {
        color: #e5714b;
      }
    }

    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.005em;
      color: #798395;
    }
  }

  .slide-container {
    margin: 0 -16px;
    .slide-item {
      padding: 0 16px;
    }
  }

  .doctor-container {
    .img-container {
      position: relative;

      .img-avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 9999px;
      }

      .img-background {
      }

      .img-mask {
        position: absolute;
        width: 30%;
        top: 0;
        right: 0;
      }
    }

    .info {
      margin-top: 24px;

      .name {
        font-weight: 800;
        font-size: 18px;
        line-height: 1.25;
        text-align: center;
        color: @color-primary;
        margin-bottom: 8px;
      }

     
    }

    .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.005em;
        color: #798395;
        text-align: center;
      }
  }


}
