.product-image {
  width: 64px;
  display: block;
  border-radius: 16px;
  cursor: pointer;
  position: relative;

  .overlay-sale {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 1;

    img {
      width: 31px;
      height: 18px;
      z-index: 0;
    }
  }

  .overlay-sale-text {
    position: absolute;
    top: 1px;
    right: 2px;
    z-index: 1;
    p {
      font-size: 11px;
      font-weight: 700;
    }
  }

  .img {
    width: 64px;
    aspect-ratio: 1;
    z-index: -1;
    transition: all 1s;
  }
}
