@import '../../../../../styles/layout.less';

.promotion-container {
  .container;
}

.grid-container {
  display: none;
  @media @sm {
    display: block;
  }
}

.slider-container {
  margin: 0 -8px;
  position: relative;
  display: block;
  @media @sm {
    display: none;
  }

  .slider-item {
    display: block;
    padding: 0 8px;
  }
}

.promotion-image {
  cursor: pointer;
  object-fit: cover;
  height: 100%;
}

.banner {
  width: 100%;
  border-radius: 16px;
  aspect-ratio: 16/9;
  min-height: 184px;
  object-fit: cover;
}
