@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.top-new-item {
  cursor: pointer;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  padding: 8px 5px;
  border-radius: 16px;
  overflow: hidden;
  column-gap: 12px;
  transition: 0.3s;
  p {
    color: #798395 !important;
    font-size: 14px;
  }
  img {
    border-radius: 8px;
    object-fit: cover;
    max-width: 150px;
    max-height: unset;
    width: 100%;
    border-radius: 8px;
  }
  .item-title {
    width: 100%;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: @color-text-n45 !important;
    }
    .date {
      padding: 8px 0;
      p {
        color: @color-text-n80;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px; // white-space: pre-wrap;
        // overflow: hidden;

        // display: -webkit-box;
        // text-overflow: ellipsis;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        strong {
          color: @color-primary;
          font-size: 12px;
        }
      }
      // text-overflow: ellipsis;
    }
    .content-ellipsis {
      display: none;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    // @media (max-width: 1100px) {
    //   max-width: 280px;
    // }
  }
  p {
    width: fit-content;
    margin: 0;
  }
  @media (max-width: 992px) {
    div:first-child {
      order: unset;
    }
  }
}
.top-new-item:hover {
  box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
}

.wrapper {
  height: fit-content;
  .item-title {
    height: fit-content;
    .title {
      font-size: 20px;
    }
    .show-evd {
      display: -webkit-box;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    row-gap: 24px;
    img {
      order: unset;
      max-width: unset;
      max-height: unset;
    }
    .item-title {
      max-width: unset;
    }
  }
}

.wrapper-small-item {
  padding: 0;
  border-radius: unset;
  .item-title {
    .title {
      .small-title {
        font-size: 14px;
      }
    }
    .date {
      display: none;
    }
    .content-ellipsis {
      display: none;
    }
  }
}
.wrapper-small-item:hover {
  box-shadow: unset;
}
