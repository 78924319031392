.loginWrapper::before {
  background: linear-gradient(180deg, #b8f3ff 0%, rgba(224, 243, 247, 0) 100%);
  opacity: 0.3;
  content: '';
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.facebook-custom-icon {
  width: 232px;
  height: 48px;
  left: 772px;
  top: 514px;
  background: #f0f3f8;
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-custom-icon {
  vertical-align: middle;
  width: 232px;
  height: 48px;
  left: 772px;
  top: 514px;
  background: #f0f3f8 !important;
  border-radius: 8px !important;
  border: none;
  color: #f0f3f8 !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .facebook-custom-icon,
  .google-custom-icon {
    width: 143px;
  }
}
