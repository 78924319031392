@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.layout-width {
  padding-top: 32px;
  padding-bottom: 88px;

  .top-news {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    gap: 24px;
  }

  .list-category {
    width: 100%;
    padding-top: 48px;
  }

  .breadcrumb {
    padding-left: 16px;
    .boundary {
      padding: 0 8px;
    }
    span:first-child {
      color: @color-primary;
      cursor: pointer;
    }
  }

  .list-news-title {
    padding: 16px;
    margin: 0;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    color: @color-text-n100;
  }
  .wrap-content {
    column-gap: 56px;
  }
  .right-content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .search-box {
      display: flex;
      flex-direction: column;
      background: @color-secondary;
      padding: 20px 24px;
      border-radius: 16px;
      row-gap: 6px;
      .search-input {
        display: flex;
        width: 100%;
        align-items: center;
        column-gap: 12px;
        padding: 12px 16px;
        border-radius: 16px;
        // position: relative;
        background-color: white;
        input {
          border: none;
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        input:focus {
          outline: none;
        }
        .search-icon {
          cursor: pointer;
          // position: absolute;
          // top: 50%;
          // right: 20px;
          // transform: translateY(-50%);
        }
      }
    }

    .list-top-news {
      background: @color-secondary;
      padding: 20px 24px;
      border-radius: 16px;
      .content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    }
  }
}
.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: @color-primary !important;
}

@media (max-width: 992px) {
  .layout-width {
    width: 100%;
    padding: 50px 24px;
  }
}
@media (max-width: 768px) {
  .layout-width {
    width: 100%;
    padding: 32px 24px;
    .top-news {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}

.list-category-title {
  background: @color-secondary;
  padding: 20px 24px;
  border-radius: 16px;
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: @color-primary;
  }
  .list-options {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .icon-rectangle {
      margin-right: 12px;
    }
    .option-text {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: @color-text-n45;
      transition: 0.3s;
    }
    .option-text:hover {
      color: @color-primary;
    }
  }
}
.row-detail {
  min-height: 90vh;
  .layout-width;
  padding: 50px 20px;
}
