@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.right-nav-container {
  width: 400px;
  width: 100%;

  .right-nav {
    background: #e0f3f7;
    border-radius: 16px;
    padding: 25px;
    margin-bottom: 20px;
    width: 100%;

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #1a9cc6;
      margin-bottom: 15px;
    }

    .search {
      width: 100%;
      position: relative;
      border-radius: 8px;
      box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);

      .input {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background: @color-text-white;
        outline: none;
        border: none;
        padding-left: 10px;
        padding-right: 50px;
        font-size: 14px;
      }

      ::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.005em;
        color: #b6bdcb;
      }

      .icon {
        color: #c4c4c4;
        position: absolute;
        top: 10px;
        right: 12px;
        font-size: 20px;
      }
    }

    .label {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #576071;
    }

    .description {
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      color: #1a9cc6;
      margin-right: 5px;
    }
  }
}
