@import '../../styles/layout.less';

.shop-online {
  &__header {
    .container;
    box-shadow: 0px 0px 16px rgba(69, 125, 245, 0.08);
  }
  &__content {
    margin-top: 72px;
    background: linear-gradient(to bottom, #def3fc, #f4f8fb);
    min-height: 100vh;
  }
}


.shop-online-profile {
  background-color: #f4f8fb;
}

.container {
  .container;
  padding-bottom: 24px;
}
