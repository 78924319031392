.top-partner-slider {
  .slick-dots {
    bottom: -50px;

    li {
      width: auto;

      button {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 9999px;
        background-color: #fff;
        opacity: 1;
        transition: all 200ms ease-in-out 0ms;

        &:hover {
          background-color: #e5714b;
        }

        &::before {
          display: none;
        }
      }
    }

    .slick-active button {
      background-color: #e5714b;
      padding: 0 12px;
    }
  }
}
