@import '../../../../styles/user/colors.less';
@import '../../../../styles/user/styles.less';

.root {
  position: fixed;
  bottom: 0;
  z-index: 11;
  background: #ffffff;
  right: 24px;
  box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.36);
  border-radius: 16px 16px 0px 0px;
  padding: 12px 20px 10px;
  h2 {
    padding-left: 10px;
    padding-right: 27px;
    font-size: 14px;
    color: #1a9cc6;
    line-height: '22px';
    margin-bottom: 0;
    font-weight: 700;
  }
  .top-title {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .wrap-layout {
    display: flex;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }
  .active.wrap-layout {
    padding-top: 15px;
    max-height: 100vh;
    padding-bottom: 10px;
  }
  .btn-show {
    transition: 0.2s;
  }
  .btn-show.active-btn {
    transform: rotate(180deg);
  }
  .img-social {
    width: 108px;
  }
  a:first-child .img-social {
    margin-right: 16px;
  }
}

@media (max-width: 992px) {
  .root {
    display: none;
  }
}
