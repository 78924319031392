@import '../../../styles/user/colors.less';
@import '../../../styles/user/styles.less';

.home-page {
  width: 100%;
  background-color: #ffffff;

  .introduce {
    margin-bottom: 25px;
  }

  .orange-background {
    position: relative;
    width: 100vw;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      pointer-events: none;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .product-promotion-container {
    background: linear-gradient(to bottom, #e0f3f7, #fff);
    position: relative;
  }
}
