@import '../../../styles/layout.less';

.login-dialog {
  position: fixed;
  inset: 0;
  z-index: 1300;
  background-color: white;
  @media @lg {
    position: static;
    inset: auto;
    top: calc(100% + 16px);
    right: 0;
    width: 320px;
    box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
    border-radius: 16px;
  }
}
