@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.searchbar2 {
  width: 100%;
  position: relative;
  border-radius: 8px;

  .input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background: @color-text-white;
    outline: none;
    border: none;
    padding-left: 50px;
    padding-right: 130px;
    font-size: 14px;
    box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #b6bdcb;
  }

  .icon {
    color: #c4c4c4;
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 30px;
  }

  .button {
    margin-left: 12px;
    background: @color-primary;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    @media (max-width: @break-point-1) {
      margin-left: 0px;
    }
  }
}
