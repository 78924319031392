.btn-detail {
  display: block;
  width: 100%;
}

.status-transport {
  width: auto;
  display: none;

  @media screen and (max-width: 576px) {
    margin-top: 14px;
    display: inline-flex !important;
  }
}
