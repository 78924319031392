@import '../../../../../styles/user/styles.less';
@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/layout.less';

.blog-container {
  .container;
  padding: 16px;

  .blog {
    margin-bottom: 50px;

    .header {
      margin-bottom: 40px;

      .title {
        font-weight: 800;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: #29313f;
        margin-bottom: 16px;

        span {
          color: @color-primary;
        }
      }

      .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.005em;
        color: #798395;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: stretch;

      .item-horizontal {
        margin: 5px;
        cursor: pointer;
        height: 100%;

        .item {
          background: #ffffff;
          border-radius: 16px;
          width: 392px;
          width: 100%;
          padding: 10px;

          &:hover {
            box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
          }

          .img {
            margin-bottom: 20px;
            width: 100%;

            img {
              width: 360px;
              height: 200px;
              object-fit: cover;
              border-radius: 8px;
            }
          }

          .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #576071;
            mix-blend-mode: normal;
            text-align: left;
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .time {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.005em;
            color: #798395;
            mix-blend-mode: normal;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin-bottom: 10px;
            margin-right: 5px;
          }

          .type {
            margin-left: 5px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #1a9cc6;
            mix-blend-mode: normal;
            flex: none;
            order: 2;
            flex-grow: 0;
          }

          .content {
            overflow: hidden;
            white-space: nowrap;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.005em;
            color: #798395;
            mix-blend-mode: normal;
            text-overflow: ellipsis;
            white-space: break-spaces;
            height: 50px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .item-vertical {
        margin: 5px;
        cursor: pointer;
        width: 100%;

        .item {
          background: #ffffff;
          border-radius: 16px;
          padding: 8px 4px;
          width: 100%;
          @media @md {
            padding: 10px 20px;
          }

          &:hover {
            box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
          }

          .img {
            height: 100%;
            img {
              border-radius: 8px;
              height: 72px;
              width: 127px;
              width: 100%;
              // height: 100%;
              object-fit: cover;
            }
          }

          .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #576071;
            mix-blend-mode: normal;
            text-align: left;
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .time {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: -0.005em;
            color: #798395;
            mix-blend-mode: normal;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin-bottom: 10px;
            margin-right: 5px;
            @media @md {
              font-size: 14px;
            }
          }

          .type {
            margin-left: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 22px;
            color: #1a9cc6;
            mix-blend-mode: normal;
            flex: none;
            order: 2;
            flex-grow: 0;
            @media @md {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
