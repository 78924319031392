@import '../../../../../styles/layout.less';

.banner-container {
  .container;

  .slide-image img {
    height: 300px;
    object-fit: cover;
    border-radius: 16px;
  }

  .side-image img {
    height: 147px;
    object-fit: cover;
    border-radius: 16px;
  }
}

.slider-container {
  position: relative;

  .button-prev {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-20%, -50%);
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 8px 32px rgba(20, 146, 187, 0.16);
    z-index: 1;
    transition: transform 150ms ease-in-out 0ms;

    &:hover {
      transform: translate(-20%, -50%) scale(1.05);
    }

    &:active {
      transform: translate(-20%, -50%) scale(1);
    }
  }

  .button-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(20%, -50%);
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 8px 32px rgba(20, 146, 187, 0.16);
    z-index: 1;
    transition: transform 150ms ease-in-out 0ms;

    &:hover {
      transform: translate(20%, -50%) scale(1.05);
    }

    &:active {
      transform: translate(20%, -50%) scale(1);
    }
  }
}
