@import '../../../../styles/breakpoint.less';

.account {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 70px;

  .account-popover {
    position: absolute;
    top: 100%;
    right: 0;
    width: 240px;
    padding: 8px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
    z-index: 10;
    transition: all 150ms ease-in-out 0ms;
    opacity: 0;
    transform: scale(0.9);
    pointer-events: none;

    ul {
      a,
      div {
        display: flex;
        align-items: center;
        border-radius: 12px;
        font-weight: 600;
        color: #576071;
        padding: 8px 12px;
        line-height: 1;
        transition: all 150ms ease-in-out 0ms;

        &:hover {
          background-color: #e0f3f7;
          color: #1a9cc6;
        }
        &::before {
          display: none;
        }
      }

      img {
        width: 20px;
        margin-right: 12px;
      }
    }
  }

  &:hover .account-popover {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}
