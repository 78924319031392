.contact-section {
  background: #ffffff;
  text-align: center;
  padding-bottom: 30px;
}
.doctor-service-1 span {
  font-weight: 800;
  font-size: 28px;
  line-height: 40px;
  color: #29313f;
}
.map-sophie {
  margin-top: 65px;
}
.doctor-service-1 .online {
  color: #1a9cc6;
}
.contact-section > .contact-content-detail {
  align-self: stretch;
}
.sub-title {
  color: #757575;
  font-size: 16px;
  max-width: 600px;
  margin: 0px auto 0;
}
.contact-content {
  text-align: left;
  padding-top: 40px;
}

.contact-content-icon {
  background: #e0f3f7;
  border-radius: 0px 16px;
  width: 32px;
  height: 32px;
  position: relative;
  margin: 15px 30px 0 0;
}

.contact-svg {
  position: absolute;
  top: -15px;
  right: -15px;
}

.contact-content-detail p {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1a9cc6;
  margin-bottom: 0;
}

.contact-content-detail span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: #798395;
}

.color-grey {
  color: #798395 !important;
}
.blog-item-big p {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #576071;
  mix-blend-mode: normal;
  margin-top: 20px;
}

@media only screen and (max-width: 1023px) {
  .contact-content .row .col-lg-4 {
    flex: 100%;
    max-width: 100%;
    padding: 20px 50px;
  }
  .contact-content .row .row .col-sm-1 {
    flex: 80px;
    max-width: 80px;
  }
  .contact-content .row .row .col-10 {
    flex: 1;
    padding-left: 0;
    max-width: 100%;
  }
  .contact-content .row .row {
    flex-wrap: nowrap;
  }
  .contact-content .row .row .contact-content-detail {
    padding-left: 0;
  }
}
@media only screen and (max-width: 600px) {
  .contact-content .row .col-lg-4 {
    flex: 100%;
    max-width: 100%;
    padding: 20px 24px;
  }
}

@media only screen and (max-width: 576px) {
  .contact-content-item {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .contact-content-item {
    padding: 20px 50px;
  }

  .contact-section {
    padding-top: 0;
  }

  .tax {
    padding-left: 10px;
  }

  .contact-content-detail {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .contact-content-detail {
    padding-left: 20px;
  }
}
