.title-policy {
  font-size: 28px;
  line-height: 40px;
  color: #29313f;
  font-weight: 800;
}
.terms-text p {
  font-size: 14px;
  line-height: 22px;
  // color: #798395;
  color: #798395;
  font-weight: 400;
  margin-bottom: 16px;

}

.terms-text h3 {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 20px;
  color: #29313f;
}
.terms-text p b {
  color: #29313f;
  font-size: 20px;
  line-height: 28px;
}
.terms-text ul {
  padding-left: 2rem;
  list-style: disc;
}
.terms-text ul li {
  color: #798395;
  margin: 8px 0;
  font-size: 14px;
}
