@import '../../../styles/user/colors.less';

.item-product {
  display: block;
  background-color: @color-text-white;
  border-radius: 16px;
  width: 100%;
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;

  &:hover {
    .img {
      // scale: 1.1;
      z-index: -1;
      border-radius: 16px 16px 0px 0px;
    }
  }

  .overlay-sale {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 1;

    img {
      width: 51px;
      height: 32px;
      z-index: 0;
      border-radius: 0px 16px 0px 16px;
    }
  }

  .overlay-sale-text {
    position: absolute;
    top: 5px;
    right: 10px;

    .text {
      z-index: 10000;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
    }
  }

  .product-image {
    overflow: hidden;
    aspect-ratio: 1;
  }

  .img {
    width: 100%;
    aspect-ratio: 1;
    z-index: -1;
    transition: all 1s;
    min-height: 164px;
    border-radius: 16px 16px 0px 0px;
    object-fit: cover;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #576071;
    mix-blend-mode: normal;
    min-height: 66px !important;
    height: 66px !important;
  }

  .price {
    font-weight: 700;
    // font-size: 16px;
    line-height: 24px;
    color: #f0224f;
    mix-blend-mode: normal;
  }

  .old-price {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.005em;
    text-decoration-line: line-through;
    color: #798395;
    mix-blend-mode: normal;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1a9cc6;
    border-radius: 8px;
    outline: none;
    border: none;
    color: white;
    width: 32px;
    height: 32px;
  }
}
