.side-bar {
  width: 100%;
  .menu-item {
    position: relative;
    width: 100%;
    background: linear-gradient(#56b8d2, #0f8fb9);
    border-radius: 10px 10px 0 0;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    .contact {
      .account-name {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
      }
      .contact-phone {
        margin-top: 5px;
        display: flex;
        justify-content: flex-start;
        .number-phone {
          margin-left: 10px;
          color: #b1e1ea;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    .avt {
      .avatar {
        width: 56px;
        height: 56px;
      }
    }
    .logo {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .list-item {
    padding: 10px;
    background-color: #fff;
    width: 100%;

    .active {
      background-color: #e0f3f7;
    }

    .item-category {
      width: 100%;
      padding: 10px 0;
      justify-content: flex-start;
      .item-name {
        margin-left: 10px;
      }
    }
  }
}
