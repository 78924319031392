// @import '~antd/lib/style/themes/default.less';
// @import '~antd/dist/antd.less';
@import './colors.less';
@import './styles.less';
@import './contact.less';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
  font-family: Inter, Arial, sans-serif;
}

.ant-layout-header {
  padding: 0;
  background-color: @color-text-white;
}

.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-horizontal > .ant-menu-submenu:hover::after,
.ant-menu-horizontal > .ant-menu-item-active::after,
.ant-menu-horizontal > .ant-menu-submenu-active::after,
.ant-menu-horizontal > .ant-menu-item-open::after,
.ant-menu-horizontal > .ant-menu-submenu-open::after,
.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-horizontal > .ant-menu-submenu-selected::after,
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border: 0px !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #576071 !important;
}

.menu-item-custom .ant-menu-title-content:hover {
  color: #1890ff !important;
  transition: all 0.5s !important;
}

.ant-menu-title-content {
  font-weight: 500 !important;
  color: #576071 !important;
}

.ant-menu-overflow
  .ant-menu
  .ant-menu-root
  .ant-menu-horizontal
  .ant-menu-light {
  border-bottom: 0px !important;
}

.ant-layout-content {
  background-color: @color-text-white;
}

#root > section > div > section > aside > div > ul {
  height: 100vh;
}
