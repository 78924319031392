@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';
@import '../../../../../styles/layout.less';

.about-container {
  position: relative;
  width: 100vw;
  background-color: white;

  .about {
    .part-1 {
      .layout-width;
      padding: 50px 16px;

      @media (max-width: @break-point-1) {
        text-align: center !important;
        width: 100vw !important;
      }

      .title {
        font-weight: 800;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 5px;
        color: @color-primary;
        margin: 0 auto;
        width: 100%;
      }

      .subtitle {
        .title;
        color: #29313f;
        margin-bottom: 10px;
      }

      .subsubtitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        color: #576071;
      }

      .paragraph {
        width: 100%;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.005em;
        margin-bottom: 15px;
        color: #798395;
      }

      .paragraph-2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #1a9cc6;
      }

      .about-quote-mark {
        width: 100%;
        width: 48px;
        width: 48px;
      }

      .img-doctor-about {
        width: 515px;
        width: 547px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.part-2 {
  padding: 50px 10px;
  background: linear-gradient(
    180deg,
    rgb(233, 251, 255) 0%,
    rgba(255, 255, 255, 0.3) 50%
  );
  width: 100vw;
  min-height: 500px;
  > div {
    .container;
  }

  .title {
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    text-align: left;
    margin-bottom: 40px;
    color: #29313f;
  }

  .search-bar {
    .layout-width;
    margin: 0 auto;
    margin-bottom: 20px;

    @media (max-width: @break-point-2) {
      width: 90vw;
    }
  }

  .border-bottom {
    background-color: #b8f3ff;
    height: 1px;
    width: 97%;
    margin: 0 auto;

    @media (max-width: @break-point-2) {
      width: 94%;
    }
  }

  .item-container {
    .layout-width;

    @media (max-width: @break-point-2) {
      width: 90vw;
    }

    .item {
      margin-bottom: 10px;
      padding: 20px;
      border-radius: 12px;
      // border-bottom: 1px solid #b8f3ff;

      .label {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #29313f;
        margin-bottom: 10px;
      }

      .sublabel {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.005em;
        color: #798395;
        white-space: nowrap;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #1a9cc6;
        }
      }

      .btn {
        background-color: rgb(219, 241, 246);
        border-radius: 8px;
        border: none;
        outline: none;
        width: 120px;
        height: 40px;
        width: 100%;

        .text {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #1a9cc6;
        }
      }

      &:hover {
        box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
        background-color: @color-text-white;
      }
    }
  }
}
