@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.list-category__item {
  .category-title {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    margin-bottom: 8px;
    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 40px;
      color: @color-text-n100;
      margin: 0;
    }
    .see-more {
      cursor: pointer;
      display: flex;
      align-items: center;
      column-gap: 9px;
      color: @color-primary;
      .text {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
      .icon {
        height: fit-content;
      }
    }
  }
  .list-news {
    display: grid;
    // gap: 16px;
    grid-template-columns: 33.33% 33.33% 33.33%;

    // grid-template-rows: auto auto auto auto;
  }
}

@media (max-width: 768px) {
  .list-category__item {
    .list-news {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      // gap: 8px;
      // grid-template-columns: 33.33% 33.33% 33.33%;
      // grid-template-rows: auto auto auto auto;
    }
  }
  .category-title {
    flex-direction: column;
    align-items: center;
    padding: unset;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    padding-bottom: 9px;
  }
}
