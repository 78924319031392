@import '../../../styles/user/colors.less';
@import '../../../styles/user/styles.less';

.layout-width {
  padding-top: 32px;
  padding-bottom: 88px;
  .top-news {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    gap: 24px;
    padding: 0 20px;
  }

  .list-category {
    width: 100%;
    padding-top: 48px;
  }

  .breadcrumb {
    padding-left: 16px;
    margin-bottom: 20px;
    .boundary {
      padding: 0 8px;
    }
    span:first-child {
      color: @color-primary;
      cursor: pointer;
    }
  }

  .list-item {
    margin-top: 10px;
    .not-found {
      width: 100%;
      padding-left: 16px;
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: @color-text-n80;
        letter-spacing: -0.005em;
      }
      .content {
        padding-top: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img {
          max-width: 160px;
        }
        &__title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: @color-text-n30;
        }
        &__sub-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: @color-text-n30;
          letter-spacing: -0.005em;
        }
      }
    }
  }

  .list-news-title {
    padding: 16px 8px;
    padding-bottom: 0px;
    margin: 0;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    color: @color-text-n100;
    span {
      color: @color-primary;
    }
  }
  .wrap-content {
    column-gap: 56px;
  }
  .right-content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .list-category-title {
      background: @color-secondary;
      padding: 20px 24px;
      border-radius: 16px;
      .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: @color-primary;
      }
      .list-options {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .icon-rectangle {
          margin-right: 12px;
        }
        .option-text {
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: @color-text-n45;
          transition: 0.3s;
        }
        .option-text:hover {
          color: @color-primary;
        }
      }
    }
    .list-top-news {
      background: @color-secondary;
      padding: 20px 20px;
      border-radius: 16px;
      .content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    }
  }
  .search-box {
    display: flex;
    flex-direction: column;
    background: @color-secondary;
    padding: 20px 24px;
    border-radius: 16px;
    row-gap: 6px;
    margin-bottom: 20px;
    .search-input {
      display: flex;
      width: 100%;
      align-items: center;
      column-gap: 12px;
      padding: 12px 16px;
      border-radius: 16px;
      // position: relative;
      background-color: white;
      input {
        border: none;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      input:focus {
        outline: none;
      }
      .search-icon {
        cursor: pointer;
        // position: absolute;
        // top: 50%;
        // right: 20px;
        // transform: translateY(-50%);
      }
    }
  }
}
.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: @color-primary;
}

@media (max-width: 900px) {
  .layout-width {
    width: 100%;
    padding: 50px 24px;
  }
}
@media (max-width: 600px) {
  .layout-width {
    width: 100%;
    padding: 32px 24px;
    .top-news {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: unset;
    }
    .list-news-title {
      font-size: 24px;
    }
  }
}
@media (max-width: 600px) {
  .layout-width {
    width: 100%;
    padding: 32px 17px;
    .top-news {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: unset;
    }
    .list-news-title {
      font-size: 24px;
    }
  }
}

// detail news
.blog-category {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: #1a9cc6;
}
.row-detail {
  padding: 50px 20px;
  .layout-width;
}
.blog-times span {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: #798395;
  mix-blend-mode: normal;
}
.content-blog {
  padding: 30px 0 80px;
  figure {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
}

.breadcrumb-detail {
  margin-bottom: 30px;
  color: #798395;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
  .boundary {
    color: #798395;
    padding: 0 8px;
  }
}
.img-bg img {
  width: 100%;
  display: block;
  padding: 20px 0;
}
.title-detail {
  padding-top: 32px;
  font-weight: 800;
  font-size: 28px;
  line-height: 48px;
  margin: 0 0 10px;
  color: #29313f;
  @media (max-width: @break-point-1) {
    font-size: 24px;
    line-height: 32px;
  }
}
.root-link {
  color: #1a9cc6;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.normal-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #798395;
}
.addition-blog {
  display: flex;
  justify-content: space-between;
}
