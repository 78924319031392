@import '../../../../../styles/user/colors.less';
@import '../../../../../styles/user/styles.less';

.appoitment-step {
  text-align: center;
  .image-step {
    position: relative;
    width: 100%;
    .appoitment-image {
      width: 100%;
    }
    .overlay {
      z-index: -1;
      position: absolute;
      background: @color-secondary;
      top: 16px;
      left: 16px;
      width: 100%;
      height: 100%;
      border-radius: 80px;
    }
  }
  .step-title {
    // max-width: 235px;
    background: #ffffff;
    border-radius: 16px;
    width: 100%;
    padding: 24px 0 8px 0;
    margin: 0 auto;
    flex-wrap: nowrap;

    .icon {
      position: relative;
      margin-right: 30px;

      img {
        width: 48px;
        height: 48px;
      }

      .number {
        position: absolute;
        top: -10px;
        right: -15px;
        font-weight: 900;
        font-size: 32px;
        line-height: 44px;
        color: #1a9cc6;
      }
    }
    .title {
      color: #1a9cc6;
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .note {
    color: #798395;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 992px) {
  .appoitment-step {
    .step-title {
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
      .icon {
        margin-right: 22px;
        img {
          width: 40px;
          height: 40px;
        }

        .number {
          font-weight: 900;
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
  }
}
