@import '../../../styles/user/colors.less';
@import '../../../styles/user/styles.less';

.about-container {
  position: relative;
  width: 100vw;

  .about-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .about {
    .layout-width;
    padding: 50px 16px;

    .part-1 {
      margin-bottom: 20px;

      @media (max-width: @break-point-1) {
        text-align: center !important;
        width: 100vw !important;
      }

      .title {
        font-weight: 800;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: #29313f;
        margin-bottom: 5px;
      }

      .subtitle {
        .title;
        margin-bottom: 10px;
        color: @color-primary;
      }

      .paragraph {
        width: 80%;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.005em;
        color: #798395;
      }

      .img-doctor-about {
        width: 720px;
        width: 100%;
      }
    }

    .part-2 {
      margin-bottom: 20px;
      flex-wrap: wrap-reverse;

      @media (max-width: @break-point-1) {
        text-align: center !important;
      }

      .title {
        font-weight: 800;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 5px;
        color: @color-primary;
        width: 100%;

        @media (max-width: 1196px) {
          text-align: center !important;
        }
      }

      .subtitle {
        .title;
        color: #29313f;
        margin-bottom: 10px;
        width: 100%;
      }

      .subsubtitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        width: 100%;
        color: #576071;

        @media (max-width: 1196px) {
          text-align: center !important;
        }
      }

      .paragraph {
        width: 80%;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.005em;
        margin-bottom: 15px;
        width: 100%;
        color: #798395;

        @media (max-width: 1196px) {
          text-align: center !important;
        }
      }

      .paragraph-2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        color: #1a9cc6;
      }

      .about-quote-mark {
        width: 100%;
        width: 48px;
      }

      .img-doctor-about {
        width: 552px;
        width: 100%;
      }
    }
  }

  .part-3 {
    position: relative;
    width: 100vw;
    overflow: hidden;
    padding: 80px 20px;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: @break-point-1) {
      text-align: center !important;
      width: 100vw !important;
    }

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 40px;
      text-align: left;
      margin-bottom: 5px;
      color: @color-primary;
      width: 100%;

      @media (max-width: 1196px) {
        text-align: center !important;
      }
    }

    .subtitle {
      .title;
      margin-bottom: 10px;
      color: #576071;
    }

    .subsubtitle {
      margin-bottom: 10px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.005em;
      color: #798395;

      @media (max-width: 1196px) {
        text-align: center !important;
      }
    }

    .paragraph {
      width: 80%;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #576071;
      margin-bottom: 15px;
      width: 100%;

      @media (max-width: 1196px) {
        text-align: center !important;
      }
    }

    .operator-container {
      .layout-width;
      margin-bottom: 40px;

      @media (max-width: @break-point-1) {
        width: 90vw;
      }

      .doctor-container {
        position: relative;
        margin: 10px;
        margin: 0 auto;

        .doctor {
          width: 192px;
          height: 259.76px;
          width: 100%;
          height: 100%;
          border-radius: 192px;
          margin-bottom: 15px;
          z-index: 2;
          overflow: auto;
        }

        .doctor-behind {
          position: absolute;
          top: 0;
          right: -10px;
          width: 100%;
          height: 96%;
          border-radius: 192px;
          z-index: 1;
        }

        .mask {
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
          width: 64px;
          height: 64px;
          z-index: 3;
        }

        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #1a9cc6;
          margin-bottom: 10px;
          z-index: 100000;
        }

        .description {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.005em;
          color: #798395;
          z-index: 100000;
        }
      }
    }
  }
}
