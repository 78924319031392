@import '../../../../../styles/user/styles.less';
@import '../../../../../styles/user/colors.less';

.service-container {
  .layout-width;
  margin-bottom: 100px;
  flex-wrap: wrap-reverse;

  .img-doctor {
    img {
      width: 456px;
      height: 570px;
      height: 100%;
      width: 100%;
    }

    @media (max-width: @break-point-1) {
      margin-bottom: 50px;
    }
  }

  .title {
    .layout-width;
    @media (min-width: @break-point-1) {
      text-align: left;
      margin: unset;
      padding-left: 15px;
    }
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    color: @color-text-n100;
    width: 80%;
    margin-bottom: 15px;
    text-align: center;

    span {
      color: @color-primary;
    }
  }

  .service {
    .layout-width;
    background: #ffffff;
    border-radius: 16px;
    width: 100%;
    height: 96px;
    height: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    .icon {
      position: relative;
      margin-right: 30px;

      img {
        width: 48px;
        height: 48px;
      }

      .number {
        position: absolute;
        top: -10px;
        right: -15px;
        font-weight: 900;
        font-size: 32px;
        line-height: 44px;
        color: #1a9cc6;
      }
    }

    .item-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #1a9cc6;
    }

    .item-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.005em;
      color: #798395;
    }

    .button {
      background: #f4f6f8;
      border-radius: 12px;
      width: 40px;
      height: 40px;
      border: none;
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 22px;
        height: 21px;
      }

      @media (max-width: @break-point-1) {
        display: none;
      }
    }
  }

  .service-01 {
    &:hover {
      box-shadow: 0px 16px 48px rgba(20, 146, 187, 0.16);
    }
  }

  .service-02 {
    &:hover {
      box-shadow: 0px 16px 48px rgba(242, 230, 255);
    }
  }

  .service-03 {
    &:hover {
      box-shadow: 0px 16px 48px rgba(255, 224, 230);
    }
  }

  .service-04 {
    &:hover {
      box-shadow: 0px 16px 48px rgba(255, 246, 215, 0.7);
    }
  }
}
